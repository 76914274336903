import React from "react"
import { TextField } from "@material-ui/core/"
import { StandardTextFieldProps } from "@material-ui/core/TextField"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { color } from "../theme"

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  root: {
    height: 50,
    color: color.text,
    backgroundColor: color.palette.offWhite,
    justifyContent: "center",
    borderRadius: 3,
    border: `1px solid ${color.palette.grey4}`,
    padding: "0px 15px",
    transition: "all 150ms",
    "&::placeholder": {
      color: color.dim,
    },
    "&:hover": {
      backgroundColor: color.transparent,
    },
    "&:focus-within": {
      backgroundColor: color.transparent,
    },
  },
  multiline: {
    minHeight: 180,
    alignItems: "flex-start",
    fontSize: 16,
    color: color.text,
    backgroundColor: color.palette.offWhite,
    borderRadius: 3,
    border: `1px solid ${color.palette.grey4}`,
    padding: 15,
    transition: "all 150ms",
    "&::placeholder": {
      color: color.dim,
    },
    "&:hover": {
      backgroundColor: color.transparent,
    },
    "&:focus-within": {
      backgroundColor: color.transparent,
    },
  },
  noLeftPadding: {
    paddingLeft: 0,
  },
  disabled: {
    color: color.text,
    "&:hover": {
      backgroundColor: color.palette.offWhite,
    },
  },
})

export const Input: React.FC<StandardTextFieldProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { className, placeholder, type, multiline, InputProps, disabled, ...rest } = props

  const getInputClassName = () => {
    return multiline
      ? classes.multiline
      : InputProps?.startAdornment
      ? `${classes.root} ${classes.noLeftPadding}`
      : classes.root
  }

  return (
    <div className={classes.container}>
      <TextField
        {...rest}
        placeholder={placeholder && t(placeholder)}
        type={type || "text" || "number"}
        multiline={multiline}
        InputProps={{
          ...InputProps,
          disableUnderline: true,
          classes: {
            root: `${disabled && classes.disabled} ${getInputClassName()} ${className}`,
            input: disabled ? classes.disabled : undefined,
          },
        }}
        label={undefined}
        disabled={disabled}
      >
        {props.children}
      </TextField>
    </div>
  )
}
