import React from "react"
import { useTranslation } from "react-i18next"
import { fade } from "@material-ui/core/styles"
import InputBase from "@material-ui/core/InputBase"
import SearchIcon from "@material-ui/icons/Search"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { color } from "../../theme"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    search: {
      height: 40,
      minWidth: 200,
      position: "relative",
      flex: 1,
      //maxWidth: 400,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 1.0),
      },
      marginRight: 20,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
      border: `1px solid ${color.palette.grey8}`,
      transition: "all 150ms",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: "Muli",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  })
)

const SearchBar = (props: any) => {
  const { width, placeholder, search } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const styles = width ? { width } : {}

  return (
    <div className={classes.search} style={styles}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onKeyUp={search}
        placeholder={t(placeholder)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  )
}

export default SearchBar
