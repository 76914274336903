import React, { useState, useEffect, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, BannerForm, Loader } from "../components"
import { makeStyles } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useStores } from "../models/root-store"
import { IBanner, CommonAttributes } from "pancho-types"

const useStyles = makeStyles({
  container: {
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
    padding: "10px 0",
    display: "flex",
    flex: 1,
  },
})

const CreateNewBannerScreen: React.FC = () => {
  const { container } = useStyles()
  const { bannerStore } = useStores()
  const { fetching } = bannerStore
  const [banner, setBanner] = useState<(IBanner & CommonAttributes) | null>(null)
  const { id } = useParams<{ id: string }>()

  const getBanner = async (id: string) => {
    const newBanner = await bannerStore.getBanner(id)
    if (newBanner) {
      setBanner(newBanner)
    }
  }

  const memoizedGetBanners = useCallback(getBanner, [])

  useEffect(() => {
    if (id) {
      memoizedGetBanners(id)
    }
  }, [id, memoizedGetBanners])

  const renderBannerForm = () => {
    if (fetching) return <Loader />
    return <BannerForm banner={banner} />
  }

  return (
    <Screen header="screens.bannerManagement">
      <div className={container}>{renderBannerForm()}</div>
    </Screen>
  )
}

export default observer(CreateNewBannerScreen)
