import React, { useState, useEffect, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, AdForm, Loader } from "../components"
import { makeStyles } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useStores } from "../models/root-store"
import { ICoupon, CommonAttributes } from "pancho-types"

const useStyles = makeStyles({
  container: {
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
    padding: "10px 0",
    display: "flex",
    flex: 1,
  },
})

const CreateNewAdScreen: React.FC = () => {
  const { container } = useStyles()
  const { couponStore } = useStores()
  const { fetching } = couponStore
  const [coupon, setCoupon] = useState<(ICoupon & CommonAttributes) | null>(null)
  const { id } = useParams<{ id: string }>()

  const getCoupon = async (id: string) => {
    const newCoupon = await couponStore.getCoupon(id)
    if (newCoupon) {
      setCoupon(newCoupon)
    }
  }

  const memoizedGetCoupons = useCallback(getCoupon, [])

  useEffect(() => {
    if (id) {
      memoizedGetCoupons(id)
    }
  }, [id, memoizedGetCoupons])

  const renderAdForm = () => {
    if (fetching) return <Loader />
    return <AdForm coupon={coupon} />
  }

  return (
    <Screen header="screens.ad">
      <div className={container}>{renderAdForm()}</div>
    </Screen>
  )
}

export default observer(CreateNewAdScreen)
