import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { CouponStoreModel } from "../coupon-store/CouponStore"
import { FeedbackStoreModel } from "../feedback-store/FeedbackStore"
import { ImageStoreModel } from "../image-store/ImageStore"
import { MessageStore } from "../message-store/MessageStore"
import { NotificationStore } from "../notification-store/NotificationStore"
import { QrCodeStoreModel } from "../qrcode-store/QRCodeStore"
import { RestaurantStoreModel } from "../restaurant-store/RestaurantStore"
import { UserStoreModel } from "../user-store/UserStore"
import { BannerStoreModel } from "../banner-store/BannerStore"
/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model("RootStore")
  .props({
    messageStore: types.optional(MessageStore, {}),
    userStore: types.optional(UserStoreModel, { state: "LOGGED_OUT" }),
    qrCodeStore: types.optional(QrCodeStoreModel, {}),
    restaurantStore: types.optional(RestaurantStoreModel, {}),
    notificationStore: types.optional(NotificationStore, {}),
    couponStore: types.optional(CouponStoreModel, {}),
    imageStore: types.optional(ImageStoreModel, {}),
    feedbackStore: types.optional(FeedbackStoreModel, {}),
    bannerStore: types.optional(BannerStoreModel, {}),
  })
  .actions(self => ({
    resetState: () => {
      self.messageStore = MessageStore.create()
      self.userStore = UserStoreModel.create({ state: "LOGGED_OUT" })
      self.qrCodeStore = QrCodeStoreModel.create()
      self.restaurantStore = RestaurantStoreModel.create()
      self.notificationStore = NotificationStore.create()
      self.couponStore = CouponStoreModel.create()
      self.imageStore = ImageStoreModel.create()
      self.feedbackStore = FeedbackStoreModel.create()
      self.bannerStore = BannerStoreModel.create()
    },
  }))

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
