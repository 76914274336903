import React, { useEffect, useState, ChangeEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, Table, TableProperty, Loader } from "../components"
import { makeStyles } from "@material-ui/styles"
import { SearchBar } from "../components/layout"
import { IRestaurant, CommonAttributes } from "pancho-types"
import { useStores } from "../models/root-store"

const useStyles = makeStyles({
  container: {
    padding: 50,
    display: "flex",
    flexDirection: "column",
  },
  searchBar: {
    marginBottom: 30,
  },
})

const properties: TableProperty<IRestaurant>[] = [
  { key: "name", title: "name" },
  { key: "email", title: "email" },
  { key: "address", title: "address" },
  { key: "city", title: "city" },
  { key: "postalCode", title: "postalCode" },
  { key: "phoneNumber", title: "phoneNumber" },
  { key: "link", title: "link" },
]

const RestaurantsScreen: React.ComponentType = observer(() => {
  const { restaurantStore } = useStores()
  const { restaurants, restaurantsTotal, fetching } = restaurantStore
  const { container, searchBar } = useStyles()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const pageSize = 10

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    restaurantStore.getRestaurants(currentPage, pageSize, searchQuery)
  }

  const getRestaurants = async (page = 0) => {
    await restaurantStore.getRestaurants(page, pageSize, search)
  }
  const memoizedGetRestaurants = useCallback(getRestaurants, [])

  useEffect(() => {
    memoizedGetRestaurants()
  }, [memoizedGetRestaurants])

  const onChangePage = (event: any, page: number) => {
    if (restaurantsTotal > pageSize * page || page > -1) {
      setCurrentPage(page)
      getRestaurants(page)
    }
  }

  const renderTable = () => {
    if (restaurants && restaurants.length > 0) {
      return (
        <Table<IRestaurant & CommonAttributes>
          total={restaurantsTotal}
          page={0}
          pageSize={10}
          onChangePage={onChangePage}
          items={restaurants}
          properties={properties}
          edit={false}
        />
      )
    } else if (fetching) {
      return <Loader />
    }
    return null
  }

  const renderContent = () => {
    if (restaurants) {
      return (
        <div className={container}>
          <div className={searchBar}>
            <SearchBar width="60%" search={handleSearch} placeholder={"restaurants.search"} />
          </div>
          {renderTable()}
        </div>
      )
    }
  }
  return <Screen header="screens.restaurants">{renderContent()}</Screen>
})

export default RestaurantsScreen
