export const palette = {
  black: "#000000",
  white: "#ffffff",
  grey1: "#4d4d4d",
  darkGrey: "#4F4F4F",
  grey2: "#939AA4",
  grey3: "#828282",
  grey4: "#E0E0E0",
  grey5: "#F4F4F4",
  grey6: "#F2F2F2",
  grey7: "#343434",
  grey8: "#C1C1C1",
  grey9: "#B8B5B5",
  offWhite: "#F7F7F7",
  angry: "#dd3333",
  mustardYellow: "#F4B902", //pääväri
  yellow: "#FCE49A", //vaaleampi keltainen
  sand: "#FFF7DF", //vaalean keltainen
  highlightColor: "#A21211", //punainen
  red: "#B3392B",
  redDarker: "#A91425",
  redDarkest: "#B20521",
  redLighter: "#B64F5B",
  redAlternate: "#B51730",
  redAlternateDarker: "#991222",
  dimmedGrey: "rgba(77, 77, 79, 0.5)",
  dimmerGrey: "rgba(77, 77, 79, 0.3)",
  dimmedWhite: "rgba(255, 255, 255, 0.5)",
  dimmerWhite: "rgba(255, 255, 255, 0.2)",
  sideBarBorder: "rgba(0, 0, 0, 0.1)",
  borderGrey: "#B8B5B5",
}
