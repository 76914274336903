import React, { useState, useEffect, ChangeEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { Screen, Table, Tabs, TableProperty, CreateButton, Text, Loader } from "../components"
import { makeStyles } from "@material-ui/styles"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/layout"
import { ICoupon, CommonAttributes } from "pancho-types"
import { RootPaths, SubPaths } from "../routers/Paths"
import Add from "../static/add-icon.svg"
import moment from "moment"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  tabsAndSearch: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 30,
  },
  tabsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 30,
  },
  searchWrapper: {
    marginTop: 30,
  },
  buttonContainer: {
    alignSelf: "flex-end",
    marginTop: -40,
    marginRight: 20,
  },
  createButton: {
    width: 140,
  },
})

const properties: TableProperty<ICoupon>[] = [
  { key: "title", title: "title" },
  { key: "validUntil", title: "validUntil" },
]

const tabs = [
  { value: "dateLimited", label: "ad.period" },
  //{ value: "unpublished", label: "ad.unpublished" }, //TODO: fix backend --> change published string to date or other solution
]

const AdManagementScreen: React.ComponentType = observer(() => {
  const { t } = useTranslation()
  const { couponStore } = useStores()
  const { coupons, couponsTotal, fetching, getCoupons } = couponStore
  const { container, tabsAndSearch, buttonContainer, tabsWrapper, createButton, searchWrapper } = useStyles()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const pageSize = 10

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
  }

  const handleGetCoupons = async (page = 0) => {
    const dateLimited = selectedTab === "dateLimited"
    const published = selectedTab !== "unpublished"
    await getCoupons(page ?? currentPage, pageSize, search, dateLimited, published)
  }

  const memoizedGetCoupons = useCallback(handleGetCoupons, [currentPage, pageSize, search, selectedTab])

  useEffect(() => {
    memoizedGetCoupons()
  }, [memoizedGetCoupons])

  const toSingle = (id: number) => {
    history.push(`${RootPaths.ad}${SubPaths.edit}/${id}`)
  }

  const onRadioClick = (value: string) => {
    setSelectedTab(value)
    setCurrentPage(0)
    handleGetCoupons(0)
  }

  const onChangePage = (event: any, page: number) => {
    setCurrentPage(page)
    handleGetCoupons(page)
  }

  const renderTable = () => {
    if (coupons && coupons.length) {
      const mappedCoupon = coupons.map(coupon => {
        const validUntil = coupon.validUntil ? moment(coupon.validUntil).format("DD.MM.yyyy") : t("ad.forNow")
        const title = coupon.title.slice(0, 120) + (coupon.title.length > 120 ? " ..." : "")
        return {
          ...coupon,
          validUntil,
          title,
        }
      })
      return (
        <Table<ICoupon & CommonAttributes>
          total={couponsTotal}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          toSingle={toSingle}
          items={mappedCoupon}
          properties={properties}
          edit={true}
        />
      )
    } else if (fetching) {
      return <Loader />
    }
    return null
  }

  const renderContent = () => {
    if (coupons) {
      return (
        <div className={container}>
          <div className={tabsAndSearch}>
            <div className={tabsWrapper}>
              <Tabs onRadioClick={onRadioClick} buttons={tabs} selected={selectedTab} defaultChecked="" />
            </div>
            <div className={searchWrapper}>
              <SearchBar width="100%" search={handleSearch} placeholder="ad.searchByTitle" />
            </div>
          </div>
          {renderTable()}
          <div className={buttonContainer}>
            <CreateButton className={createButton} url={`${RootPaths.ad}${SubPaths.create}`}>
              <img src={Add} alt="add icon" />
              <Text preset="buttonCreate" tx="Uusi" />
            </CreateButton>
          </div>
        </div>
      )
    }
  }

  return <Screen header="screens.adManagement">{renderContent()}</Screen>
})

export default AdManagementScreen
