import React, { useState } from "react"
import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { color } from "../theme"
import Button from "./Button"
import { RadioButtons } from "./RadioButtons"
// import InputDatePicker from "./InputDatePicker"
import DateRangeIcon from "@material-ui/icons/DateRange"
import ScheduleIcon from "@material-ui/icons/Schedule"
// import InputTimePicker from "./InputTimePicker"

const useStyles = makeStyles({
  container: {
    backgroundColor: color.white,
    border: `1px solid ${color.palette.borderGrey}`,
    padding: "24px 30px",
    display: "flex",
    flexFlow: "column nowrap",
    flex: "0 1 auto",
    height: 490,
  },
  header: {
    fontSize: 27,
    fontWeight: 500,
    marginTop: 0,
  },
  radioHeader: {
    fontFamily: "Muli",
    fontWeight: 500,
    fontSize: 16,
    color: color.palette.grey7,
    marginBottom: 15,
  },
  radioContainer: {
    backgroundColor: color.palette.offWhite,
    border: `1px solid ${color.palette.borderGrey}`,
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  field: {
    borderRadius: 3,
    border: `1px solid ${color.line}`,
    height: 50,
    width: 160,
    fontSize: 14,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  buttonText: {
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: 16,
    margin: "7px 40px",
  },
  cancelButton: {},
  cancelText: {
    marginRight: 24,
  },
  progress: {
    color: color.white,
  },
  datePickerInput: {
    fontFamily: "Muli",
    paddingLeft: 10,
    fontSize: 14,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  dateLabel: {
    fontFamily: "Muli",
    marginRight: 10,
  },
  timeLabel: {
    fontFamily: "Muli",
    marginRight: 19,
  },
  icon: {
    marginLeft: 2,
    marginRight: 10,
  },
  dateIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  timeContainer: {
    marginLeft: 20,
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
  },
  radioButtonsContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
})
interface PublishFormProps {
  downloadQR?(): void
  onCancel?(): void
  update?: boolean
  disabled?: boolean
  loading?: boolean
  title?: string
}

const PublishForm: React.FC<PublishFormProps> = props => {
  const { onCancel, disabled, loading, title } = props
  const classes = useStyles()
  const [selectedRadio, setSelectedRadio] = useState("print")

  const onRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.target.value)
  }

  const renderButtonContent = () => {
    if (loading) return <CircularProgress classes={{ root: classes.progress }} />
    return <span className={classes.buttonText}>{"Julkaise"}</span>
  }

  const downloadQR = () => {
    const canvas = document.getElementById("123458") as HTMLCanvasElement | null
    if (!canvas) {
      return null
    }
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = "123458.png"
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const buttons = [
    {
      value: "instantly",
      label: "Heti",
    },
    {
      value: "certainTime",
      label: "Tiettynä ajankohtana",
    },
  ]

  return (
    <div className={classes.container}>
      <h3 className={classes.header}>{title}</h3>
      <span className={classes.radioHeader}>Julkaisuajankohta</span>
      <div className={classes.radioContainer}>
        <div className={classes.radioButtonsContainer}>
          <RadioButtons onRadioClick={onRadioClick} buttons={buttons} checked="instantly" />
        </div>
        <div className={classes.dateContainer}>
          <label className={classes.dateLabel}>Pvm</label>
          <div className={classes.field}>
            {/*  <InputDatePicker inputClassName={classes.datePickerInput} /> */}
            <DateRangeIcon className={classes.dateIcon} />
          </div>
        </div>
        <div className={classes.dateContainer}>
          <label className={classes.timeLabel}>Klo</label>
          <div className={classes.field}>
            {/*  <InputTimePicker></InputTimePicker> */}
            <ScheduleIcon className={classes.icon} />
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={disabled}
          onClick={() => (selectedRadio === "print" ? downloadQR() : null)}
        >
          {renderButtonContent()}
        </Button>
        <Button className={classes.button} onClick={onCancel}>
          <span className={classes.buttonText}>Peruuta</span>
        </Button>
      </div>
    </div>
  )
}
export default PublishForm
