import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Link } from "react-router-dom"
import qs from "query-string"
import { observer } from "mobx-react"
import { color } from "../theme"
import { Input, Button } from "../components"
import { useStores } from "../models/root-store"
import Snackbar from "../components/layout/Snackbar"
import { isStrongPassword } from "../utils"
import { useLocation } from "react-router-dom"
import { Fade } from "@material-ui/core"
import { joinClassnames } from "../utils/joinClassnames"

import logo from "../static/pancho-logo.png"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: color.palette.white,
  },
  loginContainer: {
    flex: 0.5,
    padding: "60px 80px",
    position: "relative",
  },
  header: {
    fontSize: 64,
    fontWeight: "normal",
    textTransform: "uppercase",
    color: color.text,
  },
  alignCenter: {
    textAlign: "center",
  },
  secondary: {
    fontSize: 18,
    color: color.text,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  label: {
    fontFamily: "Muli",
    fontWeight: 400,
    fontSize: 24,
    textIndent: 15,
    marginTop: 40,
    marginBottom: 10,
    color: color.palette.black,
  },
  input: {
    height: 70,
    fontSize: 20,
    backgroundColor: color.palette.offWhite,
    fontFamily: "Muli",
    "&:placeholder": {},
  },
  formBottom: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    marginTop: 40,
  },
  buttonContent: {
    fontSize: 24,
    textTransform: "uppercase",
    margin: "5px 50px",
    fontWeight: 400,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  newsContainer: {
    position: "relative",
    display: "flex",
    flex: 0.5,
    flexFlow: "column nowrap",
    justifyContent: "center",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: color.primary,
  },
  logoContainer: {
    zIndex: 3,
    display: "flex",
    flex: 0.8,
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "60%",
  },
  done: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: color.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    zIndex: 999,
    justifyContent: "center",
    alignItems: "center",
  },
  linkContainer: {
    marginTop: 20,
    fontFamily: "muli",
    fontSize: "18px",
  },
  link: {
    color: "#005EB8",
  },
})

const DeletionInstructionScreen: React.ComponentType = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.loginContainer}>
        <h1 className={classes.header}>{t("deletion.header")}</h1>
        <p>{t("deletion.instructions")}</p>
        <ol>
          <li>{t("deletion.instructions-1")}</li>
          <li>
            <span>
              {t("deletion.instructions-2")}
              <a href={`mailto:${t("deletion.instructions-3")}`}>{t("deletion.instructions-3")}</a>
            </span>
          </li>
        </ol>
      </div>

      <div className={classes.newsContainer}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
      </div>
    </div>
  )
})

export default DeletionInstructionScreen
