import React, { FC } from "react"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import { color } from "../theme"
import CalendarIcon from "../static/pvm-valinta.svg"

const useStyles = makeStyles({
  input: {
    fontFamily: "Muli",
    padding: "10px 16px",
    fontSize: 14,
    backgroundColor: color.white,
    border: `1px solid ${color.lighterLine}`,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    height: 28,
  },
  keyboardButton: {
    borderRadius: 0,
    border: `1px solid ${color.lighterLine}`,
    borderLeftWidth: 0,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: color.palette.grey6,
    padding: 0,
    marginLeft: -8,
    height: 50,
    width: 41,
  },
  buttonImage: {
    opacity: 0.7,
  },
})

interface InputDatePickerProps {
  value: moment.Moment | null
  onChange: (fieldName: "pDate", date: moment.Moment | null) => void
  inputClassName?: any
  disabled?: boolean
}

export const InputDatePicker: FC<InputDatePickerProps> = ({ value, onChange, disabled }) => {
  const { input, keyboardButton, buttonImage } = useStyles()

  const handleDateChange = (date: moment.Moment | null) => {
    onChange("pDate", date)
  }

  return (
    <KeyboardDatePicker
      id="date-picker-inline"
      disableToolbar
      variant="inline"
      format="DD.MM.yyyy"
      value={value}
      keyboardIcon={<img src={CalendarIcon} alt="calendar icon" className={buttonImage} />}
      KeyboardButtonProps={{ classes: { root: keyboardButton } }}
      onChange={date => handleDateChange(date)}
      InputProps={{
        disableUnderline: true,
        classes: { input },
      }}
      disabled={disabled}
    />
  )
}
