import React, { FC } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"
import { color } from "../theme"
import moment from "moment"

const useStyles = makeStyles({
  root: {
    fontFamily: "Muli",
    padding: "10px 16px",
    fontSize: 14,
    backgroundColor: color.white,
    border: `1px solid ${color.lighterLine}`,
    height: 41,
    width: "100%",
    borderRadius: 3,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  form: {
    width: "100%",
  },
})

interface InputTimePickerProps {
  onChange: (field: "pTime", date: moment.Moment) => void
  value: moment.Moment
  disabled?: boolean
}

export const InputTimePicker: FC<InputTimePickerProps> = ({ onChange, value, disabled }) => {
  const { root, input, form } = useStyles()

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const split = e.target.value.split(":").map(it => Number(it)) // [0] = hours, [1] = minutes
    const time = moment()
      .set("hours", split[0])
      .set("minutes", split[1] || 0)
    onChange("pTime", time)
  }

  return (
    <form className={form}>
      <TextField
        id="time"
        onBlur={handleBlur}
        type="time"
        placeholder="HH:mm"
        defaultValue="00:00"
        InputProps={{ disableUnderline: true, classes: { root, input } }}
        inputProps={{
          step: 300, // 5 min
        }}
        disabled={disabled}
      />
    </form>
  )
}
