import React from "react"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { color } from "../theme"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexFlow: "column nowrap",
    height: "100%",
    width: "100%",
  },
  headerContainer: {
    background: color.white,
    width: "100%",
    display: "flex",
    flex: "0 0 auto",
  },
  header: {
    "@media (min-width:600px)": {
      fontSize: 44,
      fontWeight: "normal",
      margin: "30px 50px",
    },
    fontSize: 30,
    fontWeight: "normal",
    margin: "20px 40px",
  },
  horizontal: {
    display: "flex",
    flex: 1,
  },
})

interface ScreenProps {
  header: string
  horizontal?: boolean
}

const Screen: React.FC<ScreenProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h2 className={classes.header}>{t(props.header)}</h2>
      </div>
      <div className={props.horizontal ? classes.horizontal : undefined}>{props.children}</div>
    </div>
  )
}

export default observer(Screen)
