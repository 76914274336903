import { Button as MButton, CircularProgress, Fade, WithStyles } from "@material-ui/core/"
import { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import { createStyles, withStyles } from "@material-ui/core/styles"
import React from "react"
import { Link } from "react-router-dom"
import { color } from "../theme"
import { joinClassnames } from "../utils/joinClassnames"

const styles = () =>
  createStyles({
    root: {
      backgroundColor: color.primary,
      color: color.text,
      fontFamily: "Muli",
      textTransform: "lowercase",
      textDecoration: "none",
      fontWeight: 600,
      borderRadius: 4,
      transition: "all 250ms",
      position: "relative",
      "&:hover": {
        backgroundColor: color.primary,
        opacity: 0.666,
      },
    },
    loadingContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: color.primary,
      borderRadius: 4,
    },
    circularProgress: {
      color: color.white,
    },
  })

interface CreateButton extends WithStyles<typeof styles> {
  loading?: boolean
  url?: string
}

const CreateButton: React.FC<ButtonBaseProps & CreateButton> = props => {
  const { classes, id, onChange, type, disabled, children, className, onClick, loading, url } = props

  const styles = joinClassnames(classes.root, className)

  const renderButton = () => (
    <MButton
      id={id}
      className={styles}
      onClick={onClick}
      onChange={onChange}
      type={type}
      disabled={loading || disabled}
    >
      {children}
      <Fade in={loading}>
        <div className={classes.loadingContainer}>
          <CircularProgress size={26} classes={{ root: classes.circularProgress }} color="secondary" />
        </div>
      </Fade>
    </MButton>
  )

  return url ? (
    <Link style={{ textDecoration: "none" }} to={url}>
      {renderButton()}
    </Link>
  ) : (
    renderButton()
  )
}

export default withStyles(styles)(CreateButton)
