import { Instance, types, flow, getParent, SnapshotOut, cast } from "mobx-state-tree"
import { api } from "./../../services/api"
import { RootStore } from "../root-store"
import { FlowType } from "pancho-rest-api"

const QrCodeModel = types.model("QrCodeModel", {
  qrCodeId: types.maybeNull(types.number),
  validUntil: types.maybeNull(types.string),
  qrCodeHash: types.string,
  username: types.maybeNull(types.string),
  active: types.boolean,
  createdAt: types.string,
})

const EventModel = types.model("EventModel", {
  username: types.string,
  createdAt: types.string,
})

type QrCodeModel = Instance<typeof QrCodeModel>
type EventModel = Instance<typeof EventModel>

export const QrCodeStoreModel = types
  .model("QrCodeStore")
  .props({
    fetching: types.optional(types.boolean, false),
    events: types.array(EventModel),
    qrCode: types.optional(QrCodeModel, {
      qrCodeId: null,
      validUntil: null,
      qrCodeHash: "",
      username: "",
      active: false,
      createdAt: "",
    }),
  })
  .actions(self => ({
    getDownloadEvents: flow(function* (qrCodeId): FlowType {
      self.fetching = true
      const result = yield api.qrCode.getAllDownloadEventsByQrCodeId(qrCodeId)
      if (result) {
        self.events = cast(result)
        self.fetching = false
        return true
      }
      self.fetching = false
      return false
    }),
  }))
  .actions(self => ({
    createNewDownloadEvent: flow(function* (qrCodeId): FlowType {
      self.fetching = true
      const result = yield api.qrCode.createNewDownloadEvent(qrCodeId)
      if (result) {
        yield self.getDownloadEvents(qrCodeId)
        self.fetching = false
        return true
      }
      self.fetching = false
      return false
    }),
  }))
  .actions(self => ({
    getQrCode: flow(function* (restaurantId): FlowType {
      self.fetching = true
      const result = yield api.qrCode.getOne(restaurantId)

      if (result) {
        yield self.getDownloadEvents(result.qrCodeId)
        self.qrCode = cast(result)
        self.fetching = false
        return result
      }
      self.qrCode = {
        qrCodeId: null,
        validUntil: null,
        qrCodeHash: "",
        username: "",
        active: false,
        createdAt: "",
      }
      self.events.clear()
      self.fetching = false
      return false
    }),
  }))
  .actions(self => ({
    createQrCode: flow(function* (restaurantId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.qrCode.create(restaurantId)
      if (result) {
        messageStore.setMessage("qrcode.qrCreated", "success")
        yield self.getQrCode(restaurantId)
        return true
      }
      messageStore.setMessage("qrcode.error", "error")
      return false
    }),
  }))

type QrCodeStoreType = Instance<typeof QrCodeStoreModel>
export interface QrCodeStore extends QrCodeStoreType {}
type QrCodeStoreSnapshotType = SnapshotOut<typeof QrCodeStoreModel>
export interface QrCodeStoreSnapshot extends QrCodeStoreSnapshotType {}
