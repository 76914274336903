import React, { FC } from "react"
import { Switch, Route, BrowserRouter, Redirect, useLocation } from "react-router-dom"
import { AuthenticatedRouter } from "./AuthenticatedRouter"
import { useStores } from "../models/root-store"
import { observer } from "mobx-react"
import { LoginScreen } from "../screens"
import { RootPaths } from "./Paths"
import ResetPasswordScreen from "../screens/ResetPasswordScreen"
import DeletionInstructionScreen from "../screens/DeletionInstructionScreen"

export const LoginRedirect = observer(() => {
  const { pathname } = useLocation()
  const isInLogin = pathname.endsWith(RootPaths.login)
  const isInResetPassword =
    pathname.endsWith(RootPaths.resetPassword) || pathname.endsWith(RootPaths.deletionInstructions)
  const {
    userStore: { isLoggedIn },
  } = useStores()
  if (!isLoggedIn && !isInLogin && !isInResetPassword) {
    return <Redirect to={RootPaths.login} />
  }
  if (isLoggedIn && isInLogin) {
    return <Redirect to={RootPaths.index} />
  }
  return null
})

export const RootRouter: FC = observer(() => {
  const {
    userStore: { isLoggedIn },
  } = useStores()
  return (
    <BrowserRouter>
      <LoginRedirect />
      <Switch>
        <Route path={RootPaths.resetPassword} exact>
          <ResetPasswordScreen />
        </Route>
        <Route path={RootPaths.deletionInstructions} exact>
          <DeletionInstructionScreen />
        </Route>
        <Route path={RootPaths.login} exact>
          <LoginScreen />
        </Route>
        {isLoggedIn && <AuthenticatedRouter />}
      </Switch>
    </BrowserRouter>
  )
})
