import { RootPaths } from "./../../routers/Paths"
import UserManagement from "../../static/kayttajanhallinta.svg"
import Restaurants from "../../static/ravintolat.svg"
import QrCode from "../../static/qr-koodit.svg"
import Coupons from "../../static/tarjoukset.svg"
import Notifications from "../../static/tiedotteet.svg"
import Message from "../../static/mail.svg"
import BannerManagement from "../../static/banner.svg"
import { Roles, RoleType } from "pancho-types"
export interface SidebarItem {
  header: string
  items: Item[]
}
export interface Item {
  title: string
  icon: string
  activeIcon: string
  path: string
  allowedRoles: RoleType[]
}

export const SidebarItems = [
  {
    header: "general",
    items: [
      {
        title: "userManagement",
        icon: UserManagement,
        activeIcon: UserManagement,
        path: RootPaths.users,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.USER],
      },
      {
        title: "restaurants",
        icon: Restaurants,
        activeIcon: Restaurants,
        path: RootPaths.restaurants,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.USER],
      },
      {
        title: "QRcode",
        icon: QrCode,
        activeIcon: QrCode,
        path: RootPaths.qrCode,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.USER],
      },
      {
        title: "adManagement",
        icon: Coupons,
        activeIcon: Coupons,
        path: RootPaths.ad,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN],
      },
      {
        title: "notifications",
        icon: Notifications,
        activeIcon: Notifications,
        path: RootPaths.notifications,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN],
      },
      {
        title: "bannerManagement",
        icon: BannerManagement,
        activeIcon: BannerManagement,
        path: RootPaths.banner,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN],
      },
      {
        title: "feedbacks",
        icon: Message,
        activeIcon: Notifications,
        path: RootPaths.feedbacks,
        allowedRoles: [Roles.SUPERADMIN],
      },
    ],
  },
]
