import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { ListItem as MUIListItem, ListItemIcon, ListItemText, Theme } from "@material-ui/core"
import classNames from "classnames"
import { Item } from "../nav"
import { ListLink } from "./ListLink"
import makeStyles from "@material-ui/styles/makeStyles"
import { color } from "../../../theme"

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    height: 60,
    padding: 0,
    transition: "200ms all",
    borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
  },
  lastItem: {
    height: 60,
    padding: 0,
    transition: "200ms all",
  },
  listItemOpen: {
    padding: "0 20px",
  },
  listIcon: {
    height: 40,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    alignItems: "center",
    justifyContent: "center",
    color: color.white,
    margin: 0,
    backgroundColor: color.dimmerWhite,
    transition: "200ms all",
  },
  listIconDark: {
    backgroundColor: color.palette.darkGrey,
  },
  listIconActive: {
    backgroundColor: color.dimWhite,
  },
  icon: {
    height: 20,
    width: 20,
    filter: "invert(21%) sepia(40%) saturate(4433%) hue-rotate(350deg) brightness(95%) contrast(78%)", // changing img colors is what it is
  },
  listItemText: {
    fontSize: 20,
    fontWeight: 500,
    color: color.palette.black,
    fontFamily: "mailartrubberstampregular",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: "block",
  },
  listItemTextOpen: {
    width: 260,
  },
}))
interface ListItemProps {
  item: Item
  darkIcon?: boolean
  open: boolean
}

export const ListItem: React.FC<ListItemProps> = ({ item, darkIcon, open }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()

  const isCurrentPage = (itemPath: string) => location.pathname.includes(itemPath)

  return (
    <MUIListItem
      key={item.title}
      className={classes.listItemOpen}
      classes={{ root: darkIcon ? classes.lastItem : classes.listItem }}
      disableGutters
      button
    >
      <ListLink to={item.path}>
        <ListItemText
          className={classes.listItemTextOpen}
          classes={{
            primary: classNames(classes.listItemText, {
              [classes.listItemTextOpen]: open,
            }),
          }}
        >
          {t(`sidebar.${item.title}`)}
        </ListItemText>
        <ListItemIcon
          classes={{
            root: classNames(classes.listIcon, {
              [classes.listIconActive]: isCurrentPage(item.path),
              [classes.listIconDark]: darkIcon,
            }),
          }}
        >
          <img src={item.icon} alt={t(item.title)} className={classes.icon} />
        </ListItemIcon>
      </ListLink>
    </MUIListItem>
  )
}
