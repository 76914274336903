import React, { FC, ChangeEvent, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react"
import { IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import { ICoupon } from "pancho-types"
import moment from "moment"
import { useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import { useStores } from "../../models/root-store"
import { Input, Text, SelectInput, CreateButton, Button, InputDatePicker, ModalDialog } from "../"
import { RadioButtons } from "../RadioButtons"
import useAdFormStyles from "./AdFormStyles"
import Add from "../../static/add-icon.svg"
interface AdFormProps {
  coupon: ICoupon | null
}

type InUseType = "allRestaurants" | "selectedRestaurants"
type ValidityType = "forNow" | "timePeriod"
type UsesType = "once" | "wholePeriod" | "limited"
type PublishType = "immediately" | "publishDate"

export const AdForm: FC<AdFormProps> = observer(({ coupon }) => {
  const {
    restaurantStore: { sortedRestaurantsForUser },
    userStore: { isSuperAdmin, isAdmin, isAtLeastAdmin },
    couponStore,
    imageStore,
  } = useStores()
  const history = useHistory()
  const { t } = useTranslation()
  const s = useAdFormStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [openRemoveImageModal, setOpenRemoveImageModal] = useState(false)
  const [selectedRestaurants, setRestaurant] = useState<string[]>([])
  const [title, setTitle] = useState("")
  const [publish, setPublish] = useState<moment.Moment | null | undefined>(moment())
  const [imageId, setImageId] = useState<number | null | undefined>(null)
  const [content, setContent] = useState("")
  const [imageContent, setImageContent] = useState<string | null>()
  const [imageName, setImageName] = useState<string>()
  const [validUntil, setValidUntil] = useState<moment.Moment | null | undefined>(moment())
  const [validFrom, setValidFrom] = useState<moment.Moment | null | undefined>(moment())
  const [uses, setUses] = useState<number>(0)
  const [inUseRadio, setInUseRadio] = useState<InUseType>("allRestaurants")
  const [validRadio, setValidRadio] = useState<ValidityType>("forNow")
  const [usesRadio, setUsesRadio] = useState<UsesType>("once")
  const [publishRadio, setPublishRadio] = useState<PublishType>("immediately")
  const [uploadedFile, setUploadedFile] = useState<File | null>()

  useEffect(() => {
    if (coupon) {
      const {
        title,
        belongsToRestaurants,
        validUntil,
        validFrom,
        uses,
        publish,
        content,
        imageId,
        belongsToImage,
      } = coupon as ICoupon

      setTitle(title)
      setImageId(imageId)
      if (content) {
        setContent(content)
      }
      setImageContent(belongsToImage?.path)
      setImageName(belongsToImage?.name)
      setRestaurant(belongsToRestaurants?.map(({ id }) => id + "") || [])
      if (belongsToRestaurants?.length) {
        setInUseRadio("selectedRestaurants")
      } else {
        setInUseRadio("allRestaurants")
      }
      if (validUntil && validFrom) {
        setValidRadio("timePeriod")
        setValidFrom(moment(validFrom))
        setValidUntil(moment(validUntil))
      } else {
        setValidRadio("forNow")
      }
      setUses(uses ?? 0)
      if (uses === 1) {
        setUsesRadio("once")
      } else if (uses === 0) {
        setUsesRadio("wholePeriod")
      } else {
        setUsesRadio("limited")
      }
      if (publish) {
        setPublish(moment(publish))
        setPublishRadio("publishDate")
      } else {
        setPublishRadio("immediately")
      }
    } else if (isAdmin) {
      setInUseRadio("selectedRestaurants")
    }
  }, [coupon])

  const getUses = () => {
    if (usesRadio === "once") {
      return 1
    } else if (usesRadio === "wholePeriod") {
      return 0
    } else {
      return uses
    }
  }

  const getInUse = () =>
    isSuperAdmin && inUseRadio === "allRestaurants"
      ? null
      : selectedRestaurants.map(i => ({ restaurantId: parseInt(i, 10) }))

  const getValidUntil = () => (validRadio === "forNow" ? null : validUntil?.toISOString())
  const getValidFrom = () => (validRadio === "forNow" ? null : validFrom?.toISOString())
  const getPublish = () => (publishRadio === "immediately" ? null : publish?.toISOString())

  // disabled if in edit mode and radio button is selected as allRestaurants or admin restaurants are not in coupon's restaurants
  const adminHasOneCouponRestaurant = (): boolean =>
    !!coupon?.belongsToRestaurants?.some(r => sortedRestaurantsForUser.find(({ id }) => id === r.id))

  const isDisabledForAdmin =
    !!coupon?.id && isAdmin && (inUseRadio === "allRestaurants" || !adminHasOneCouponRestaurant())

  const isDisabled = () => {
    if (!title || isDisabledForAdmin) return true
    return inUseRadio === "selectedRestaurants" && selectedRestaurants.length === 0
  }

  const handleSave = async () => {
    if (isDisabled()) return

    let imageIdOrNewImageId = imageId
    if (uploadedFile) {
      const image = await imageStore.createImage(uploadedFile)
      imageIdOrNewImageId = image?.id
    }
    const newCoupon = {
      title,
      content,
      validUntil: getValidUntil(),
      validFrom: getValidFrom(),
      active: true,
      uses: getUses(),
      publish: getPublish(),
      imageId: imageIdOrNewImageId,
      restaurants: getInUse(),
    } as ICoupon
    if (coupon && coupon.id) {
      newCoupon.id = coupon.id
      await couponStore.updateCoupon(newCoupon as ICoupon)
    } else {
      await couponStore.createCoupon(newCoupon as ICoupon)
    }
    history.push("/tarjoukset")
  }

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle && setTitle(e.target.value)
  const handleContentTextChange = (e: ChangeEvent<HTMLInputElement>) => setContent && setContent(e.target.value)
  const handleRestaurantChange = (event: ChangeEvent<HTMLInputElement | any>) => {
    setRestaurant(event.target.value)
  }

  const inUseRadioClick = (e: ChangeEvent<HTMLInputElement>) => setInUseRadio(e.target.value as InUseType)
  const onValidRadioClick = (e: ChangeEvent<HTMLInputElement>) => setValidRadio(e.target.value as ValidityType)
  const onUsesRadioClick = (e: ChangeEvent<HTMLInputElement>) => setUsesRadio(e.target.value as UsesType)
  const onPublishRadioClick = (e: ChangeEvent<HTMLInputElement>) => setPublishRadio(e.target.value as PublishType)

  const openDeleteModal = () => setModalOpen(true)
  const closeDeleteModal = () => setModalOpen(false)
  const openImageModal = () => setImageModalOpen(true)
  const closeImageModal = () => setImageModalOpen(false)
  const imageRemoveModalOpen = () => setOpenRemoveImageModal(true)

  const cancelImageUpload = () => {
    setUploadedFile(undefined)
    closeImageModal()
  }

  const restaurantOptions = () => {
    if (!coupon?.id) {
      // if new coupon then show userRestaurants
      return sortedRestaurantsForUser.map(k => ({ label: k.name, value: k.id.toString() }))
    } else if (isSuperAdmin || !isDisabledForAdmin) {
      // if super then show all restaurants
      // or if not admin with same restaurant or with not all restaurants
      return sortedRestaurantsForUser.map(k => ({ label: k.name, value: k.id.toString() }))
    }

    // if edit shows restaurants of the coupon
    return (
      coupon?.belongsToRestaurants
        ?.slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(k => {
          return { label: k.name, value: k.id + "" }
        }) ?? []
    )
  }

  const handlePublishTimeChange = (_: any, date: moment.Moment | null) => setPublish(date)
  const handleValidFromChange = (_: any, date: moment.Moment | null) => setValidFrom(date)
  const handleValidUntilChange = (_: any, date: moment.Moment | null) => setValidUntil(date)

  const handleUsageChange = (e: ChangeEvent<HTMLInputElement>) => setUses && setUses(e.target.valueAsNumber)

  const handleCancel = () => {
    history.push("/tarjoukset")
  }

  const cancelRemoveImage = () => {
    setOpenRemoveImageModal(false)
  }

  const IN_USE_OPTIONS = [
    { label: "ad.allRestaurants", value: "allRestaurants" },
    { label: "ad.selectedRestaurants", value: "selectedRestaurants" },
  ]
  const VALID_OPTIONS = [
    { label: "ad.forNow", value: "forNow" },
    { label: "ad.validPeriod", value: "timePeriod" },
  ]
  const USAGE_OPTIONS = [
    { label: "ad.once", value: "once" }, // kerran
    { label: "ad.validExpirationDate", value: "wholePeriod" }, // voimassaoloajan
    { label: "ad.limitedUses", value: "limited" }, // käyttökertojen mukaan
  ]
  const PUBLISH_OPTIONS = [
    { label: "ad.immediately", value: "immediately" },
    { label: "ad.publishDate", value: "publishDate" },
  ]

  const deleteCouponHandler = async () => {
    if (coupon?.id) {
      await couponStore.deleteCoupon(coupon.id)
      history.push("/tarjoukset")
    }
    closeDeleteModal()
  }

  const readFile = (files: File[]) => setUploadedFile(files[0])

  const createImagePreviewURL = (file: any) => file && URL.createObjectURL(file)

  const uploadFile = async () => {
    if (uploadedFile) {
      setImageContent(createImagePreviewURL(uploadedFile))
      setImageName(uploadedFile.name)
    }
    closeImageModal()
  }

  const handleImageRemove = async () => {
    if (imageId) {
      setImageContent(null)
      setImageName("")
      setOpenRemoveImageModal(false)
      setImageId(null)
      setUploadedFile(null)
      try {
        await imageStore.deleteImage(imageId)
      } catch (error) {
        console.log(error)
      }
    } else {
      setImageContent(null)
      setOpenRemoveImageModal(false)
      setUploadedFile(null)
    }
  }

  const renderDelete = () => {
    if (!coupon?.id || isDisabledForAdmin) return null
    return (
      <IconButton onClick={openDeleteModal} className={s.deleteButton} aria-label="remove">
        <DeleteIcon classes={{ root: s.deleteIcon }} />
      </IconButton>
    )
  }

  const renderImageButtons = () => {
    if (isDisabledForAdmin) return null
    if (!imageContent && isAtLeastAdmin) {
      return (
        <CreateButton className={s.createButton} onClick={openImageModal}>
          <img className={s.addIcon} src={Add} alt="icon" />
          <Text preset="buttonCreate" tx="ad.image" />
        </CreateButton>
      )
    }
    return (
      <CreateButton className={s.editButton} onClick={imageRemoveModalOpen}>
        <Text preset="buttonCreate" tx="ad.remove" />
      </CreateButton>
    )
  }

  const renderRestaurantSelect = () => {
    if (inUseRadio === "allRestaurants") {
      return isSuperAdmin ? <div className={s.emptyContainer} /> : <Text tx="Kaikissa ravintoloissa" />
    }
    return (
      <div className={s.dropdownContainer}>
        <span className={s.label}>{t("ad.selectRestaurant")}</span>
        <SelectInput
          multiple
          className={s.selectWrapper}
          placeholder="users.selectRestaurant"
          onChange={handleRestaurantChange}
          options={restaurantOptions()}
          value={selectedRestaurants}
          disabled={isDisabledForAdmin}
        />
      </div>
    )
  }

  const renderTimePeriod = () => {
    if (validRadio === "forNow") {
      return <div className={s.emptyContainer} />
    }
    const from = moment(validFrom || new Date())
    const until = moment(validUntil || new Date())
    return (
      <div className={s.dateContainer}>
        <InputDatePicker value={from} onChange={handleValidFromChange} disabled={isDisabledForAdmin} />
        <div className={s.line} />
        <InputDatePicker value={until} onChange={handleValidUntilChange} disabled={isDisabledForAdmin} />
      </div>
    )
  }

  const renderPublish = () => {
    if (publishRadio === "immediately") return <div className={s.emptyContainer} />
    const publishTime = moment(publish)
    return (
      <div className={s.dateContainer}>
        <InputDatePicker value={publishTime} onChange={handlePublishTimeChange} disabled={isDisabledForAdmin} />
      </div>
    )
  }

  const renderAddImageModalContent = () =>
    uploadedFile ? (
      <div className={s.uploadedFileWrapper}>
        <img className={s.image} src={createImagePreviewURL(uploadedFile)} alt="coupon_image" />
        <div className={s.fileText}>
          <Text preset="smallDefault" tx={uploadedFile.name} />
        </div>
      </div>
    ) : (
      <Dropzone onDrop={readFile}>
        {({ getRootProps, getInputProps }) => (
          <div>
            <div className={s.addImageWrapper} {...getRootProps()}>
              <input {...getInputProps()} />
              <Text preset="default" tx="Raahaa kuvatiedosto tähän" />
              <Text preset="default" tx="tai" />
              <div className={s.createButtonContainer}>
                <CreateButton>
                  <Text preset="buttonCreate" tx="ad.pickImage" />
                </CreateButton>
              </div>
              <Text preset="default" text="Kuvasuhteen olisi hyvä olla 4:3" />
            </div>
          </div>
        )}
      </Dropzone>
    )

  const getHeader = () => {
    if (!coupon?.id) return "ad.createNew"
    if (coupon.id && !isDisabledForAdmin) return "common.edit"
    return "common.view"
  }

  return (
    <div className={s.formContainer}>
      <div className={s.headerContainer}>
        <Text preset="formHeader" tx={getHeader()} />
        {renderDelete()}
      </div>
      <Text preset="formLabelBold" tx="ad.heading" />
      <Input
        className={s.input}
        value={title}
        onChange={handleTitleChange}
        placeholder="ad.heading"
        disabled={isDisabledForAdmin}
      />

      <Text preset="formLabelBold" tx="ad.image" />
      <div className={s.imageContainer}>
        {imageContent && <img className={s.image} src={imageContent} alt="coupon_image" />}
        <div className={s.imageTextContainer}>
          {imageContent && <Text className={s.imageText} preset="default" text={imageName} />}
          {renderImageButtons()}
        </div>
      </div>
      <Text preset="formLabelBold" tx="ad.description" />
      <Input
        className={s.multilineInput}
        multiline
        value={content}
        onChange={handleContentTextChange}
        disabled={isDisabledForAdmin}
      />
      <Text preset="formLabelBold" tx="ad.inUse" />
      <RadioButtons
        onRadioClick={inUseRadioClick}
        buttons={IN_USE_OPTIONS}
        checked={inUseRadio}
        disabled={isDisabledForAdmin || isAdmin}
      />
      {renderRestaurantSelect()}
      <Text preset="formLabelBold" tx="ad.valid" />
      <RadioButtons
        onRadioClick={onValidRadioClick}
        buttons={VALID_OPTIONS}
        checked={validRadio}
        disabled={isDisabledForAdmin}
      />
      {renderTimePeriod()}
      <Text preset="formLabelBold" tx="ad.type" />
      <RadioButtons
        onRadioClick={onUsesRadioClick}
        buttons={USAGE_OPTIONS}
        checked={usesRadio}
        disabled={isDisabledForAdmin}
      />
      {usesRadio === "limited" && (
        <Input
          type="number"
          className={s.usesInput}
          value={uses}
          placeholder="ad.uses"
          onChange={handleUsageChange}
          disabled={isDisabledForAdmin}
        />
      )}
      <Text preset="formLabelBold" tx="ad.publish" />
      <RadioButtons
        onRadioClick={onPublishRadioClick}
        buttons={PUBLISH_OPTIONS}
        checked={publishRadio}
        disabled={isDisabledForAdmin}
      />
      {renderPublish()}
      <div className={s.buttons}>
        <Button className={s.button} onClick={handleSave} disabled={isDisabled()}>
          <Text preset="button" tx={publishRadio === "publishDate" ? "common.save" : "common.publish"} />
        </Button>
        <Button secondary onClick={handleCancel}>
          <Text preset="button" tx="common.cancel" />
        </Button>
      </div>
      <ModalDialog
        isOpen={imageModalOpen}
        title="Lisää kuva"
        onLeftClick={cancelImageUpload}
        onRightClick={uploadFile}
        leftButtonText="common.cancel"
        rightButtonText="Lataa"
      >
        {renderAddImageModalContent()}
      </ModalDialog>
      <ModalDialog
        isOpen={openRemoveImageModal}
        title="Poista kuva"
        onLeftClick={handleImageRemove}
        onRightClick={cancelRemoveImage}
        leftButtonText="common.remove"
        rightButtonText="common.cancel"
        deleteButton={true}
      />
      <ModalDialog
        isOpen={modalOpen}
        title="ad.modalTitle"
        onLeftClick={deleteCouponHandler}
        onRightClick={closeDeleteModal}
        leftButtonText="common.remove"
        rightButtonText="common.cancel"
        deleteButton={true}
      />
    </div>
  )
})
