import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import React from "react"
import { color } from "../../theme"
import moment from "moment"

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  label: {
    fontFamily: "Muli",
    fontWeight: 500,
    fontSize: 16,
    marginTop: 20,
    marginBottom: 0,
    color: color.dim,
    marginLeft: 8,
  },
  field: {
    borderRadius: 3,
    height: 50,
    fontSize: 20,
    minWidth: 300,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  fieldValue: {
    marginLeft: 8,
    fontFamily: "Muli",
    fontSize: 20,
  },
})

interface FormProps {
  loading?: boolean
  validUntil: string | undefined | null
  createdAt: string | undefined | null
  creator: string | undefined | null
}

const QrCodeInfo: React.FC<FormProps> = props => {
  const classes = useStyles()
  const { validUntil, createdAt, creator } = props
  const { t } = useTranslation()
  const currentDate = new Date()
  const expires = moment(validUntil).isBefore(currentDate) ? "qrcode.expired" : "qrcode.expires"
  const validUntilFormatted = moment(validUntil).clone().format("DD.MM.YYYY  HH:mm")
  return (
    <div className={classes.formContainer}>
      <p className={classes.label}>{t(expires)}</p>
      <div className={classes.field}>
        <span className={classes.fieldValue}>{validUntilFormatted}</span>
      </div>
      <p className={classes.label}>{t("qrcode.created")}</p>
      <div className={classes.field}>
        <span className={classes.fieldValue}>{createdAt}</span>
      </div>
      <p className={classes.label}>{t("qrcode.creator")}</p>
      <div className={classes.field}>
        <span className={classes.fieldValue}>{creator}</span>
      </div>
    </div>
  )
}

export default QrCodeInfo
