import { Button as MButton, CircularProgress, Fade, WithStyles } from "@material-ui/core/"
import { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import { createStyles, withStyles } from "@material-ui/core/styles"
import React from "react"
import { color } from "../theme"
import { joinClassnames } from "../utils/joinClassnames"

const styles = () =>
  createStyles({
    root: {
      backgroundColor: color.primary,
      boxShadow: "0px 8px 8px rgba(255, 205, 0, 0.25)",
      color: color.text,
      fontWeight: 600,
      borderRadius: 50,
      height: 50,
      transition: "all 250ms",
      position: "relative",
      "&:hover": {
        backgroundColor: color.primary,
        opacity: 0.666,
      },
    },
    root2: {
      backgroundColor: color.palette.highlightColor,
      color: color.palette.white,
      fontWeight: 600,
      borderRadius: 50,
      height: "auto",
      transition: "all 250ms",
      position: "relative",
      "&:hover": {
        backgroundColor: color.palette.highlightColor,
        opacity: 0.8,
        color: color.palette.white,
      },
    },
    loadingContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: color.primary,
      borderRadius: 50,
    },
    circularProgress: {
      color: color.white,
    },
    secondary: {
      backgroundColor: color.lighterLine,
      boxShadow: "0px 8px 8px rgba(227, 227, 227, 0.25)",
      "&:hover": {
        backgroundColor: color.lighterLine,
        opacity: 0.666,
      },
    },
    deleteButton: {
      backgroundColor: color.palette.highlightColor,
    },
  })

interface ButtonProps extends WithStyles<typeof styles> {
  loading?: boolean
  secondary?: boolean
  deleteButton?: boolean
}

const Button: React.FC<ButtonBaseProps & ButtonProps> = props => {
  const {
    classes,
    id,
    onChange,
    type,
    disabled,
    children,
    className,
    onClick,
    loading,
    secondary,
    deleteButton,
  } = props
  const secondaryClass = secondary ? classes.secondary : ""
  const deleteButtonClass = deleteButton ? classes.deleteButton : ""
  const deleteButtonStyle = deleteButton ? classes.root2 : classes.root
  const styles = joinClassnames(deleteButtonStyle, className, secondaryClass, deleteButtonClass)
  return (
    <MButton
      id={id}
      className={styles}
      onClick={onClick}
      onChange={onChange}
      type={type}
      disabled={loading || disabled}
    >
      {children}
      <Fade in={loading}>
        <div className={classes.loadingContainer}>
          <CircularProgress size={26} classes={{ root: classes.circularProgress }} color="secondary" />
        </div>
      </Fade>
    </MButton>
  )
}

export default withStyles(styles)(Button)
