import React, { useState, useEffect } from "react"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/styles"
import "./i18n"
import moment from "moment"
import "moment/locale/fi"
import { getCurrentLang } from "./utils/transforms"
import { color } from "./theme"
import { RootStore, RootStoreProvider, setupRootStore } from "./models/root-store"
import { RootRouter } from "./routers/RootRouter"
import { CssBaseline, createMuiTheme, MuiThemeProvider } from "@material-ui/core"
import { fiFI } from "@material-ui/core/locale"

const FONTS = [
  "Muli",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",")

const useStyles = makeStyles({
  "@global": {
    "#root": {
      width: "100%",
      height: "100%",
      fontFamily: FONTS,
      htmlFontSize: 10,
      backgroundColor: color.background,
    },
    button: {
      fontFamily: FONTS,
      appearance: "none",
    },
  },
})

const App: React.FC = () => {
  useStyles()
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)
  useEffect(() => {
    setupRootStore().then(setRootStore)
    moment.locale("fi")
  }, [])

  const theme = createMuiTheme({}, fiFI)

  // Show nothing before the app state is ready.
  // Can display a spinner or whatever if deemed necessary.
  if (!rootStore) {
    return null
  }

  // rootStore.userStore.checkToken()
  return (
    <RootStoreProvider value={rootStore}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={getCurrentLang()}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline>
            <RootRouter />
          </CssBaseline>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </RootStoreProvider>
  )
}

export default App
