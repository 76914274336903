import React from "react"
import { useTranslation } from "react-i18next"
import { TableHead, TableRow, TableCell } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme/"

const useStyles = makeStyles({
  root: {
    height: 50,
  },
  tableHead: {
    textAlign: "center",
    backgroundColor: color.palette.white,
    color: color.text,
    fontFamily: "Muli",
    fontSize: 18,
    fontWeight: 600,
    minWidth: 60,
    padding: "0px 16px",
    border: `1px solid ${color.lighterLine}`,
    borderTopWidth: 0,
    "&:first-child": {
      borderLeftWidth: 0,
    },
    "&:last-child": {
      borderRightWidth: 0,
      padding: "0px 16px",
    },
  },
})

interface TableHeaderProps {
  properties: {
    key: number | symbol | string
    title: string
  }[]
  edit?: boolean
}

const TableHeader: React.FC<TableHeaderProps> = ({ properties, edit }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeaderTitles = () => {
    return properties.length
      ? properties.map(it => (
          <TableCell key={String(it.key)} className={classes.tableHead}>
            {t(`headerTitle.${it.title}`)}
          </TableCell>
        ))
      : null
  }

  return (
    <TableHead>
      <TableRow classes={{ root: classes.root }}>
        {renderHeaderTitles()}
        {edit ? <TableCell className={classes.tableHead}>{t("headerTitle.edit")}</TableCell> : null}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
