import React from "react"
import { Switch, Route, Redirect, RouteProps } from "react-router-dom"
import {
  UserManagementScreen,
  QRCodeScreen,
  AdManagementScreen,
  NotificationsScreen,
  CreateNewAdScreen,
  UserScreen,
  RestaurantsScreen,
  NotificationScreen,
  BannerManagementScreen,
  CreateNewBannerScreen,
  FeedbacksScreen,
  FeedbackScreen,
} from "../screens"
import { Layout } from "../components/layout"
import { RootPaths, SubPaths } from "./Paths"

const CreateAndEditRoutes = ({ path, children }: RouteProps) => (
  <>
    <Route path={`${path}${SubPaths.create}`}>{children}</Route>
    <Route path={`${path}${SubPaths.edit}/:id`}>{children}</Route>
  </>
)

export const AuthenticatedRouter: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path={RootPaths.index}>
          <Redirect to={RootPaths.users} />
        </Route>
        <Route path={RootPaths.users} exact>
          <UserManagementScreen />
        </Route>
        <Route path={RootPaths.restaurants} exact>
          <RestaurantsScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.users}>
          <UserScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.qrCode}>
          <QRCodeScreen />
        </Route>
        <Route path={RootPaths.ad} exact>
          <AdManagementScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.ad}>
          <CreateNewAdScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.notifications} exact>
          <NotificationsScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.notifications}>
          <NotificationScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.notifications} exact>
          <NotificationsScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.notifications}>
          <NotificationScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.banner} exact>
          <BannerManagementScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.banner}>
          <CreateNewBannerScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.feedbacks} exact>
          <FeedbacksScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.feedbacks}>
          <FeedbackScreen />
        </CreateAndEditRoutes>
      </Switch>
    </Layout>
  )
}
