import React, { useState, useEffect, ChangeEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { Screen, Table, Tabs, TableProperty, CreateButton, Text, Loader } from "../components"
import { makeStyles } from "@material-ui/styles"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/layout"
import { IBanner, CommonAttributes } from "pancho-types"
import { RootPaths, SubPaths } from "../routers/Paths"
import moment from "moment"
import Add from "../static/add-icon.svg"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  tabsAndSearch: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 30,
  },
  searchWrapper: {
    marginTop: 30,
  },
  buttonContainer: {
    alignSelf: "flex-end",
    marginTop: -40,
    marginRight: 20,
  },
  createButton: {
    width: 140,
  },
})

type BannerTableType = CommonAttributes & IBanner & { status?: string; topic?: string }

const properties: TableProperty<BannerTableType>[] = [
  { key: "title", title: "title" },
  { key: "status", title: "status" },
]

const BannerManagementScreen: React.ComponentType = observer(() => {
  const { t } = useTranslation()
  const { bannerStore } = useStores()
  const { banners, bannersTotal, fetching, getBanners } = bannerStore
  const { container, tabsAndSearch, buttonContainer, createButton, searchWrapper } = useStyles()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const pageSize = 10

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    getBanners(currentPage, pageSize, searchQuery)
  }

  const handleGetBanners = async (page = 0) => {
    await getBanners(page, pageSize, search)
  }

  const memoizedGetBanners = useCallback(handleGetBanners, [])

  useEffect(() => {
    memoizedGetBanners()
  }, [memoizedGetBanners])

  const toSingle = (id: number) => {
    history.push(`${RootPaths.banner}${SubPaths.edit}/${id}`)
  }

  const onChangePage = (event: any, page: number) => {
    setCurrentPage(page)
    handleGetBanners(page)
  }

  const renderTable = () => {
    if (banners && banners.length) {
      const bannersWithStatus = banners.map(b => {
        const copy = { ...b }
        if (b.publish && !b.sentAt) return { ...copy, status: "Julkaistaan " + moment(b.publish).format("YYYY-MM-DD") }
        if ((b.publish && b.sentAt) || !b.publish) return { ...copy, status: "Julkaistu" }

        return b
      })

      return (
        <Table<IBanner & CommonAttributes>
          total={bannersTotal}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          toSingle={toSingle}
          items={bannersWithStatus}
          properties={properties}
          edit={true}
        />
      )
    } else if (fetching) {
      return <Loader />
    }
    return null
  }

  const renderContent = () => {
    if (banners) {
      return (
        <div className={container}>
          <div className={tabsAndSearch}>
            <div className={searchWrapper}>
              <SearchBar width="100%" search={handleSearch} placeholder="ad.searchByTitle" />
            </div>
          </div>
          {renderTable()}
          <div className={buttonContainer}>
            <CreateButton className={createButton} url={`${RootPaths.banner}${SubPaths.create}`}>
              <img src={Add} alt="add icon" />
              <Text preset="buttonCreate" tx="Uusi" />
            </CreateButton>
          </div>
        </div>
      )
    }
  }

  return <Screen header="screens.bannerManagement">{renderContent()}</Screen>
})

export default BannerManagementScreen
