export const RootPaths = {
  index: "/",
  restaurants: "/ravintolat",
  users: "/kayttajat",
  qrCode: "/qr-koodi",
  login: "/kirjaudu",
  resetPassword: "/palauta-salasana",
  ad: "/tarjoukset",
  notifications: "/notifikaatiot",
  banner: "/bannerin-hallinta",
  feedbacks: "/palautteet",
  deletionInstructions: "/tilin-poisto",
} as const

export const SubPaths = {
  create: "/uusi",
  edit: "/muokkaa",
}
