import { WithStyles } from "@material-ui/core/"
import { createStyles, withStyles } from "@material-ui/core/styles"
import React from "react"
import { color } from "../../theme"

const QRCode = require("qrcode.react")

const styles = () =>
  createStyles({
    qrContainer: {
      display: "flex",
      flexDirection: "column",
      width: 282,
      height: 282,
      border: `1px solid ${color.palette.grey4}`,
      marginRight: 30,
    },
  })
interface QRCodeCreatorProps extends WithStyles<typeof styles> {
  loading?: boolean
  code: string
}

const QRCodeCreator: React.FC<QRCodeCreatorProps> = props => {
  const { classes, code } = props
  return (
    <div className={classes.qrContainer}>
      <QRCode id="qr-code-canvas" value={code} size={280} level={"H"} includeMargin={true} />
    </div>
  )
}

export default withStyles(styles)(QRCodeCreator)
