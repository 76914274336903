import React, { FC } from "react"
import { makeStyles } from "@material-ui/core"
import { color } from "../theme"
import { joinClassnames } from "../utils/joinClassnames"
import { useTranslation } from "react-i18next"

interface TabButton {
  label: string
  value: string
}

export interface TabsProps {
  onRadioClick: (value: string) => void
  buttons: Array<TabButton>
  defaultChecked: string
  selected: string
}

const useStyles = makeStyles({
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tab: {
    "@media (min-width:600px)": {
      backgroundColor: color.palette.yellow,
      borderRadius: 10,
      minWidth: 180,
      height: 45,
      padding: "0 15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 15,
      borderColor: "transparent",
      cursor: "pointer",
      transition: "all 250ms",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: color.primary,
        opacity: 0.75,
        boxShadow: "0px 8px 8px rgba(244, 185, 2, 0.15)",
      },
    },
    display: "none",
  },
  checked: {
    backgroundColor: color.primary,
    outline: "transparent",
    boxShadow: "0px 8px 8px rgba(244, 185, 2, 0.25)",
    "&:focus": {
      outline: "none",
    },
  },
  text: {
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: 16,
  },
})

export const Tabs: FC<TabsProps> = props => {
  const { onRadioClick, buttons, defaultChecked, selected } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const renderTabButton = ({ label, value }: TabButton) => {
    const checked = selected === value ? classes.checked : ""
    const selectButton = () => onRadioClick(selected === value ? "" : value)
    return (
      <button
        key={value}
        type="button"
        value={value}
        className={joinClassnames(classes.tab, checked)}
        defaultChecked={defaultChecked === value}
        name="radios"
        onClick={selectButton}
      >
        <span className={classes.text}>{t(label)}</span>
      </button>
    )
  }

  const renderTabButtons = () => {
    return buttons.map(button => renderTabButton(button))
  }

  return <div className={classes.tabContainer}>{renderTabButtons()}</div>
}
