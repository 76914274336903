import React from "react"
import { CircularProgress, MenuItem } from "@material-ui/core"
import Select, { SelectProps } from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/styles"
import { color } from "../theme/color"
import { useTranslation } from "react-i18next"
import { ReactComponent as ArrowIcon } from "../static/alas.svg"

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
  },
  input: {
    fontSize: 16,
    color: color.text,
    backgroundColor: color.palette.offWhite,
    justifyContent: "center",
    borderRadius: 3,
    border: `1px solid ${color.palette.grey4}`,
    padding: "0px 15px",
    transition: "all 150ms",
    "&::placeholder": {
      color: color.dim,
    },
    "&:hover": {
      backgroundColor: color.transparent,
    },
    "&:focus-within": {
      backgroundColor: color.transparent,
    },
  },
  menuItem: {
    fontSize: 16,
    backgroundColor: color.white,
  },
  selectRoot: {
    height: 48,
    display: "flex",
    alignItems: "center",
    padding: 0,
    transition: "all 150ms",
    "&::placeholder": {
      color: color.dim,
    },
    "&:hover": {
      backgroundColor: color.transparent,
    },
    "&:focus": {
      backgroundColor: color.transparent,
    },
  },
  selectDisabled: {
    color: color.dimmer,
    cursor: "not-allowed",
    fontSize: 16,
    "&:hover": {
      backgroundColor: color.palette.offWhite,
    },
  },
  icon: {
    color: color.dim,
    zIndex: 10000,
    position: "absolute",
    right: 16,
    pointerEvents: "none",
  },
  menuRoot: {
    maxHeight: "50%",
  },
  placeholder: {
    opacity: 0.5,
    color: color.dim,
  },
})

interface Option {
  label: string
  value: string
}

interface SelectInputProps {
  options: Option[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  loading?: boolean
}

const SelectInput: React.FC<SelectProps & SelectInputProps> = props => {
  const { value, placeholder, disabled: disabledProp, className, options, loading, ...rest } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const renderOptions = () =>
    options.map(option => (
      <MenuItem key={option.value} value={option.value} classes={{ root: classes.menuItem }}>
        {t(option.label)}
      </MenuItem>
    ))

  const disabled = disabledProp || loading

  const renderIcon = () => {
    if (loading) return <CircularProgress className={classes.icon} />
    if (!disabled) return <ArrowIcon className={classes.icon} fill={color.palette.red} />
    return null
  }

  return (
    <div className={`${className} ${classes.container}`}>
      {renderIcon()}
      <Select
        {...rest}
        value={value}
        className={`${classes.input} ${disabled && classes.selectDisabled}`}
        classes={{
          select: `${classes.selectRoot} ${value === "" && classes.placeholder}`,
          disabled: classes.selectDisabled,
        }}
        IconComponent={"svg"}
        disabled={disabled}
        displayEmpty
        disableUnderline
        MenuProps={{ MenuListProps: { classes: { root: classes.menuRoot } } }}
      >
        <MenuItem value="" classes={{ root: classes.menuItem }} disabled>
          {placeholder && t(placeholder)}
        </MenuItem>
        {renderOptions()}
      </Select>
    </div>
  )
}

export default SelectInput
