import React from "react"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react"
import { IconButton, createStyles, Theme } from "@material-ui/core"
import { color } from "../../theme/color"
import { useStores } from "../../models/root-store"
import { makeStyles } from "@material-ui/styles"
import LogoutIcon from "../../static/Logout.svg"
import { Text } from "../Text"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      color: color.palette.black,
    },
    navItem: {
      minWidth: 60,
      height: 60,
      padding: 15,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: "transparent",
      [theme.breakpoints.up("md")]: {
        borderLeft: `0.5px solid ${color.palette.grey4}`,
        minWidth: 60,
        height: 60,
        padding: 15,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
    },
    logoutButton: {
      borderRadius: 0,
      height: 40,
      width: 40,
    },
    icon: {
      color: color.text,
    },
    text: {
      marginRight: 10,
    },
  })
)
const Header: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { userStore } = useStores()
  const { authenticatedUser } = userStore

  const logout = () => {
    userStore.logout()
  }

  return (
    <div className={classes.root}>
      <div className={classes.navItem}>
        <Text className={classes.text} preset="default" text={authenticatedUser?.username || ""} />
        <Text tx={`users.header.${authenticatedUser?.role}`} />
      </div>
      <div className={classes.navItem}>
        <IconButton className={classes.logoutButton} onClick={logout}>
          <img src={LogoutIcon} className={classes.icon} alt={t("header.logout")} />
        </IconButton>
      </div>
    </div>
  )
})

export default Header
