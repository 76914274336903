import React, { useState, useEffect, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, FeedbackForm, Loader } from "../components"
import { useParams } from "react-router-dom"
import { useStores } from "../models/root-store"
import { IFeedback, CommonAttributes } from "pancho-types"

const FeedbackScreen: React.FC = () => {
  const { feedbackStore } = useStores()
  const { fetching } = feedbackStore
  const [feedback, setFeedback] = useState<(IFeedback & CommonAttributes) | null>(null)
  const { id } = useParams()

  const getFeedback = async (id: number) => {
    const newFeedback = await feedbackStore.getFeedback(id)
    if (newFeedback) {
      setFeedback(newFeedback)
    }
  }

  const memoizedGetFeedbacks = useCallback(getFeedback, [])

  useEffect(() => {
    if (id) {
      memoizedGetFeedbacks(id)
    }
  }, [id, memoizedGetFeedbacks])

  const renderFeedbackForm = () => {
    if (fetching) return <Loader />
    if (feedback) {
      return <FeedbackForm feedback={feedback} />
    }
  }

  return <Screen header="screens.feedback">{renderFeedbackForm()}</Screen>
}

export default observer(FeedbackScreen)
