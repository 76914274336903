import { Instance, types, flow, getParent, SnapshotOut } from "mobx-state-tree"
import { api } from "./../../services/api"
import { RootStore } from "../root-store"
import { FlowType } from "pancho-rest-api"
import { IRestaurant } from "pancho-types"

const RestaurantModel = types.model("RestaurantModel", {
  id: types.identifierNumber,
  name: types.optional(types.string, ""),
  email: types.optional(types.string, ""),
  address: types.optional(types.string, ""),
  city: types.optional(types.string, ""),
  postalCode: types.optional(types.string, ""),
  phoneNumber: types.optional(types.string, ""),
  link: types.optional(types.string, ""),
})

type RestaurantModel = Instance<typeof RestaurantModel>

type DropdownItem = { label: string; value: any }

interface RestaurantsForUser {
  userId: number
  restaurantId: number
  belonsToRestaurant: IRestaurant
}

export const RestaurantStoreModel = types
  .model("RestaurantStore")
  .props({
    selectedRestaurantIndex: types.optional(types.number, 0),
    restaurants: types.optional(types.array(RestaurantModel), []),
    restaurantsForUser: types.optional(types.array(RestaurantModel), []),
    restaurantsTotal: types.optional(types.number, 0),
    fetching: types.optional(types.boolean, false),
  })
  .views(self => ({
    get sortedRestaurantsForUser() {
      return self.restaurantsForUser.slice().sort((a, b) => (a.name > b.name ? 1 : -1))
    },
  }))
  .views(self => ({
    get selectedRestaurant() {
      if (self.selectedRestaurantIndex in self.restaurantsForUser) {
        return self.restaurantsForUser[self.selectedRestaurantIndex]
      }
      return null
    },
    asDropdownList: (city?: string): DropdownItem[] => {
      return self.sortedRestaurantsForUser
        .filter(r => (city ? r.city.toLowerCase() === city.toLowerCase() : true))
        .map(r => ({ value: r.name, label: r.name }))
    },
    allAsDropdownList: (): DropdownItem[] => {
      return self.restaurants
        .slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(r => ({ value: r.name, label: r.name }))
    },
    asDropdownListById: (id: number): DropdownItem[] => {
      return self.sortedRestaurantsForUser.filter(r => r.id === id).map(r => ({ value: r.name, label: r.name }))
    },
    // Unique cities of restaurants formatted to dropdown list format
    citiesAsDropdownList: (): DropdownItem[] => {
      return Array.from(
        new Set(
          self.restaurants
            .slice()
            .sort((a, b) => (a.city > b.city ? 1 : -1))
            .map(r => r.city)
        )
      ).map(c => ({ value: c, label: c }))
    },
  }))
  .actions(self => ({
    getRestaurants: flow(function* (page: number, pageSize: number, search?: string): FlowType {
      self.fetching = true
      const response = yield api.restaurants.getAll({ page, pageSize, search })
      if (response) {
        self.restaurants.replace(response.results)
        self.restaurantsTotal = response.total
        self.fetching = false
        return true
      }
      self.fetching = false
      return false
    }),
  }))
  .actions(self => ({
    getRestaurantsForUser: flow(function* (): FlowType {
      self.fetching = true
      const response = yield api.restaurants.getRestaurantForUser()
      if (response) {
        const restaurantToRestaurantModel = response.map((restaurant: IRestaurant) => ({
          id: restaurant.id as number,
          name: restaurant.name || "",
          email: restaurant.email || "",
          address: restaurant.address || "",
          city: restaurant.city || "",
          postalCode: restaurant.postalCode || "",
          phoneNumber: restaurant.phoneNumber || "",
          link: restaurant.link || "",
        }))
        self.restaurantsForUser.replace(restaurantToRestaurantModel)
        self.fetching = false
        return true
      }
      self.fetching = false
      return false
    }),
  }))
  .actions(self => ({
    getRestaurant: flow(function* (restaurantId): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.restaurants.getOne(restaurantId)
      if (result) {
        self.fetching = false
        return result
      }
      messageStore.setMessage("restaurant.error", "error")
      self.fetching = false
      return null
    }),
  }))
  .actions(self => ({
    deleteRestaurant: flow(function* (restaurantId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.restaurants.delete(restaurantId)
      if (result) {
        messageStore.setMessage("restaurants.deleted", "success")
        return true
      }
      messageStore.setMessage("restaurants.error", "error")
      return null
    }),
  }))

type RestaurantStoreType = Instance<typeof RestaurantModel>
export interface RestaurantStore extends RestaurantStoreType {}
type RestaurantStoreSnapshotType = SnapshotOut<typeof RestaurantStoreModel>
export interface RestaurantStoreSnapshot extends RestaurantStoreSnapshotType {}
