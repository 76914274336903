import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Drawer, List, Hidden } from "@material-ui/core"
import { SidebarItem, Item } from "../nav"
import { ListItem } from "./ListItem"
import { useStores } from "../../../models/root-store"
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles"
import { color } from "../../../theme"
import { NavBar } from "./NavBar"
import PanchoLogo from "../../../static/pancho-logo.png"

const drawerWidth = 300

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        background: color.primary,
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      padding: 30,
      marginLeft: drawerWidth,
    },
    logoMobile: {
      width: "auto",
      height: 35,
      position: "absolute",
      left: "50%",
      transform: `translateX(-50%)`,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        display: "none",
      },
    },
    logo: {
      width: "auto",
      height: 35,
    },
    menu: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      flex: 1,
      background: color.primary,
      width: drawerWidth,
    },
    headerContainer: {
      width: drawerWidth,
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 60,
      backgroundColor: color.primary,
      padding: 0,
      borderRadius: 0,
      borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
    },
  })
)

interface SidebarProps {
  location?: Location
  menuItems: SidebarItem[]
}

const Sidebar = (props: SidebarProps) => {
  const { menu, drawer, headerContainer, logoMobile, logo, root } = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const { restaurantStore, userStore } = useStores()
  const { authenticatedUser, getAuthenticatedUser } = userStore
  const {
    /* restaurantsForUser, selectedRestaurantIndex, setSelectedRestaurantIndex, */ getRestaurantsForUser,
  } = restaurantStore
  const { menuItems } = props

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  useEffect(() => {
    const getRestaurants = async () => {
      await getRestaurantsForUser()
      await getAuthenticatedUser()
    }
    getRestaurants()
  }, [getRestaurantsForUser, getAuthenticatedUser])

  const renderItem = (item: Item) => {
    return <ListItem key={item.path} open={mobileOpen} item={item} />
  }

  const renderMenuItems = () => {
    return menuItems.map(item => {
      return (
        <List component="div" key={item.header} disablePadding>
          {item.items
            .filter(i => {
              return authenticatedUser && i.allowedRoles.includes(authenticatedUser.role)
            })
            .map(item => renderItem(item))}
        </List>
      )
    })
  }

  const renderMenu = () => {
    return (
      <div className={menu}>
        <List component="nav" disablePadding>
          {renderMenuItems()}
        </List>
      </div>
    )
  }

  const renderNavigation = () => {
    return (
      <nav>
        <Hidden mdUp implementation="css">
          <Drawer
            className={drawer}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={headerContainer}>
              <img className={logoMobile} src={PanchoLogo} alt="logo" />
            </div>
            {renderMenu()}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer className={drawer} variant="permanent" open>
            <div className={headerContainer}>
              <img className={logo} src={PanchoLogo} alt="logo" />
            </div>
            {renderMenu()}
          </Drawer>
        </Hidden>
      </nav>
    )
  }

  return (
    <div className={root}>
      <NavBar onClick={handleDrawerToggle} />
      {renderNavigation()}
    </div>
  )
}

export default observer(Sidebar)
