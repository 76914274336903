import React from "react"
import { AppBar, Toolbar, IconButton, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import PanchoLogo from "../../../static/pancho-logo.png"
import { color } from "../../../theme"
import Header from "../Header"

const drawerWidth = 300

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: color.primary,
    boxShadow: "none",
    justifyContent: "center",
    height: 60,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: color.palette.white,
      boxShadow: "none",
      borderBottom: `0.5px solid ${color.palette.grey4}`,
      padding: 0,
    },
  },
  menuButton: {
    margin: 10,
    color: color.palette.highlightColor,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarStyle: {
    padding: 0,
  },
  logoMobile: {
    width: "auto",
    height: 35,
    position: "absolute",
    left: "50%",
    transform: `translateX(-50%)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      display: "none",
    },
  },
}))
interface NavBarProps {
  onClick(): void
}

export const NavBar: React.FC<NavBarProps> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbarStyle}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onClick}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <img className={classes.logoMobile} src={PanchoLogo} alt="logo" />
        <Header />
      </Toolbar>
    </AppBar>
  )
}
