import React from "react"
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import { color } from "../theme/color"
import Rectangle from "../static/ArrowDownRedBackground.svg"
import { IRestaurant } from "pancho-types"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: color.palette.mustardYellow,
    },
    listItemText: {
      fontFamily: "Muli",
      textTransform: "none",
      paddingBottom: 10,
      marginBottom: 0,
      fontWeight: "bold",
      fontSize: 14,
      paddingLeft: 20,
      borderBottom: `1px solid ${color.palette.sideBarBorder}`,
    },
    menu: {
      maxHeight: 350,
    },
  })
)

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: "Muli",
  },
}))(MenuItem)

const StyledListItem = withStyles(() => ({
  root: {
    fontFamily: "mailartrubberstampregular",
    textTransform: "uppercase",
    paddingRight: 3,
  },
}))(ListItem)

interface SimpleListMenuProps {
  restaurants?: IRestaurant[]
  selectedIndex: number
  setSelectedIndex(index: number): void
}

export function SimpleListMenu(props: SimpleListMenuProps) {
  const { restaurants, selectedIndex, setSelectedIndex } = props
  const { t } = useTranslation()
  const { root, listItemText, menu } = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const mapRestaurants = () => {
    return (
      restaurants &&
      restaurants.map(({ name }, index) => (
        <StyledMenuItem
          key={name}
          selected={index === selectedIndex}
          onClick={event => handleMenuItemClick(event, index)}
        >
          {name}
        </StyledMenuItem>
      ))
    )
  }

  const renderList = () => {
    return (
      <List component="nav" aria-label="Device settings">
        <StyledListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label={t("sidebar.selectRestaurant")}
          onClick={handleClickListItem}
        >
          <ListItemText disableTypography primary={restaurants && restaurants[selectedIndex]?.name} />
          <ListItemIcon>
            <img src={Rectangle} alt="arrow down" />
          </ListItemIcon>
        </StyledListItem>
      </List>
    )
  }

  return (
    <div className={root}>
      <p className={listItemText}>{t("sidebar.selectRestaurant")}</p>
      {renderList()}
      <Menu
        className={menu}
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {mapRestaurants()}
      </Menu>
    </div>
  )
}
