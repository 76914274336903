import { color } from "../../theme"
import { makeStyles } from "@material-ui/core"

const BASE = {
  fontSize: 16,
  fontFamily: "Muli",
  fontWeight: 500,
}
const FORM_LABEL = {
  ...BASE,
  textIndent: 15,
  marginTop: 30,
  marginBottom: 10,
}
const SUB_TITLE = {
  ...BASE,
  marginTop: 30,
  marginBottom: 10,
  fontWeight: 600,
}

/**
 * Styles for the different presets.
 * Make sure the name of the style object matches the name of the corresponding preset.
 */
export const usePresetStyles = makeStyles({
  default: {
    ...BASE,
  },
  defaultBold: {
    ...BASE,
    fontWeight: 600,
  },
  defaultBig: {
    ...BASE,
    fontSize: 20,
  },
  formHeader: {
    fontSize: 28,
    fontFamily: "mailartrubberstampregular",
    color: color.palette.black,
    fontWeight: 500,
    margin: 0,
  },
  formHeaderSmall: {
    fontSize: 24,
    fontFamily: "mailartrubberstampregular",
    color: color.palette.black,
    fontWeight: 500,
    margin: 0,
  },
  formLabel: {
    ...FORM_LABEL,
  },
  formLabelBold: {
    ...SUB_TITLE,
  },
  button: {
    ...BASE,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "none",
    padding: "2px 30px",
    whiteSpace: "nowrap",
  },
  buttonCreate: {
    ...BASE,
    fontWeight: 600,
    fontSize: 16,
    color: color.palette.black,
    textTransform: "none",
    padding: 2,
    whiteSpace: "nowrap",
  },
  smallDefault: {
    ...BASE,
    fontSize: 12,
  },
})

type PresetType = {
  element: keyof JSX.IntrinsicElements
  style: string
}

/**
 * All the common variations of text in the app.
 */
export const presets = {
  default: {
    element: "span",
    style: "default",
  } as PresetType,
  defaultBold: {
    element: "span",
    style: "default",
  } as PresetType,
  defaultBig: {
    element: "span",
    style: "default",
  } as PresetType,
  smallDefault: {
    element: "span",
    style: "smallDefault",
  } as PresetType,
  formHeader: {
    element: "h2",
    style: "formHeader",
  } as PresetType,
  formHeaderSmall: {
    element: "h2",
    style: "formHeaderSmall",
  } as PresetType,
  formLabel: {
    element: "span",
    style: "formLabel",
  } as PresetType,
  formLabelBold: {
    element: "span",
    style: "formLabelBold",
  } as PresetType,
  button: {
    element: "span",
    style: "button",
  } as PresetType,
  buttonCreate: {
    element: "span",
    style: "button",
  } as PresetType,
}

/**
 * A list of preset names.
 */
export type TextPresets = keyof typeof presets
