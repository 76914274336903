import { RestApi } from "pancho-rest-api"
//import { ImageRequestModel } from "pancho-types"

const TOKEN_S = "token"

export class Api extends RestApi {
  rememberMe = true

  saveToken(token: string): void {
    if (this.rememberMe) {
      localStorage.setItem(TOKEN_S, token)
    }
  }

  loadToken(): string | null | undefined {
    return localStorage.getItem(TOKEN_S)
  }

  cleanUserData() {
    localStorage.clear()
  }

  /*   async getImage({ id, width, height, fit }: ImageRequestModel): Promise<Blob | null> {
    const res = await this.httpClient.get(
      `/images/${id}?width=${width}&height=${height}&fit=${fit}`,
      {},
      { responseType: "blob" }
    )
    if (res.ok) return res.data as Blob
    return null
  }

  getImageUri({ id, width, height, fit }: ImageRequestModel): string {
    return `${this.httpClient.getBaseURL()}/images/${id}?width=${width}&height=${height}&fit=${fit}`
  }*/
}

export const api = new Api()
