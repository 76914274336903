import React from "react"
import { makeStyles } from "@material-ui/core"
import { color } from "../theme"
import { joinClassnames } from "../utils/joinClassnames"

const useStyles = makeStyles({
  container: {
    "@media (min-width:600px)": {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "46px 52px",
      padding: "34px 44px",
      border: `1px solid ${color.border}`,
      background: color.white,
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 10,
    background: color.white,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
})

interface FormContainerProps {
  className?: string
}

export const FormContainer: React.FC<FormContainerProps> = ({ className, children }) => (
  <div className={joinClassnames(useStyles().container, className)}>{children}</div>
)
