import React from "react"
import { withStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Switch as MSwitch, WithStyles } from "@material-ui/core"
import { color } from "../theme"
import { joinClassnames } from "../utils/joinClassnames"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: color.primary,
      width: 42,
      height: 26,
      padding: 0,

      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: color.primary,
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},

    loadingContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: color.primary,
      borderRadius: 50,
    },
    circularProgress: {
      color: color.white,
    },
  })

interface SwitchProps extends WithStyles<typeof styles> {
  className?: string
  checked?: boolean
  disabled?: boolean
  value?: unknown
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const Switch: React.FC<SwitchProps> = props => {
  const { classes, className, checked, disabled, onChange, value } = props

  const styles = joinClassnames(classes.root, className)

  return (
    <MSwitch
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      value={value}
      disableRipple
      className={styles}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  )
}

export default withStyles(styles)(Switch)
