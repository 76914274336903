import { makeStyles } from "@material-ui/core"
import { color } from "../../theme"

export default makeStyles({
  formContainer: {
    marginBottom: 30,
    maxWidth: 900,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    backgroundColor: color.white,
    border: ` 1px solid ${color.palette.borderGrey}`,
    padding: 10,
    flex: "1 1 auto",
    "@media (min-width:600px)": {
      marginBottom: 30,
      maxWidth: 900,
      display: "flex",
      flexDirection: "column",
      backgroundColor: color.white,
      border: ` 1px solid ${color.palette.borderGrey}`,
      padding: 40,
      flex: "1 1 auto",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleText: {
    paddingBottom: 30,
  },
  input: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Muli",
    minHeight: 50,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
  },
  multilineInput: {
    display: "flex",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Muli",
    minHeight: 250,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 500,
    marginBottom: 20,
    marginTop: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  line: {
    width: 15,
    height: 5,
    borderTop: `2px solid ${color.palette.black}`,
    marginRight: 10,
    marginLeft: 10,
  },
  usesInput: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Muli",
    minHeight: 50,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
    maxWidth: 140,
    marginTop: 20,
  },
  dropdownContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  selectWrapper: {
    maxWidth: 500,
  },
  createButton: {
    width: 120,
    marginTop: 15,
  },
  editButton: {
    width: 120,
    marginLeft: 20,
    marginTop: 15,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
  },
  button: {
    marginRight: 20,
  },
  label: {
    textIndent: 15,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 16,
    fontFamily: "Muli",
    fontWeight: 500,
    color: color.text,
  },
  emptyContainer: {
    width: 10,
    height: 10,
  },
  form: {
    display: "flex",
    flex: 1,
  },
  deleteButton: {
    padding: 0,
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  deleteIcon: {
    color: color.palette.highlightColor,
    fontSize: 28,
  },
  addImageWrapper: {
    height: 200,
    width: "100%",
    border: `2px dashed ${color.primary}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 30,
    padding: "0 50px",
  },
  uploadedFileWrapper: {
    minHeight: 200,
    minWidth: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
    marginBotton: 30,
  },
  createButtonContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  imageTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  image: {
    width: 280,
    height: "100%",
    minHeight: 200,
  },
  imageText: {
    marginLeft: 20,
  },
  fileText: {
    marginTop: 10,
  },
  addIcon: {
    marginRight: 10,
  },
  timePeriodTextWrapper: {
    display: "flex",
    flexDirection: "row",
  },
})
