import React from "react"
import { observer } from "mobx-react"
import { Theme } from "@material-ui/core/styles"
import { color } from "../../theme/color"
import Snackbar from "./Snackbar"
import { Sidebar } from "./sidebar"
import { SidebarItems } from "./nav"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    backgroundColor: color.background,
    flexGrow: 1,
    zIndex: 9,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    height: 60,
    zIndex: 999,
    background: color.palette.black,
    boxShadow: "none",
    marginLeft: 60,
    width: `calc(100% - 60px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    height: 60,
    zIndex: 999,
    background: color.white,
    boxShadow: "none",
    marginLeft: 364,
    width: `calc(100% - 364px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flex: 1,
    marginTop: "60px",
    width: "100%",
    display: "flex",
  },
  toolbarRoot: {
    minHeight: 60,
  },
  toolbar: theme.mixins.toolbar,
  loading: {
    color: color.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  mainArea: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    minHeight: "100vh",
  },
}))

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = observer(props => {
  const classes = useStyles()
  const { children } = props

  return (
    <div className={classes.root}>
      <div className={classes.mainArea}>
        <Sidebar location={window.location} menuItems={SidebarItems} />
        <main className={classes.content}>{children}</main>
      </div>
      <Snackbar />
    </div>
  )
})

export default Layout
